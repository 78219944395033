import { Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loading from './components/Loading';

const EditDemo = React.lazy(() => import('./pages/demo/EditDemo'));
const ReadDemo = React.lazy(() => import('./pages/demo/ReadDemo'));
const Markbook = React.lazy(() => import('./pages/markbook'));

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<EditDemo />} />
        <Route path="/markbook" element={<Suspense fallback={<Loading />}><Markbook /></Suspense>} />
        <Route path="/demo/edit" element={<EditDemo />} />
        <Route path="/demo/read" element={<ReadDemo />} />
      </Routes>
    </div>
  );
}
